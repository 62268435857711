import utils from "@/utils/index";

export default {
    email: value => {
        if (utils.isValidEmail(value)) {
            return true;
        }
        return "inserisci una mail valida";
    },
    maxchars: (num) => {
        return value => {
            if (value && value.length > num) {
                return `max ${num} caratteri`;
            }
            return true;
        }
    },
    numchars: (num) => {
        return value => {
            if (value && value.length !== num) {
                return `devono esserci ${num} caratteri`;
            }
            return true;
        }
    },
    minInt: (min) => {
        return value => {
            if (value) {
                const val = utils.toInt(value, min - 1)
                if (val < min) return `deve essere un numero intero maggiore o uguale a ${min}`;
            }
            return true;
        }
    },
    maxInt: (max) => {
        return value => {
            if (value) {
                const val = utils.toInt(value, max + 1)
                if (val > max) return `deve essere un numero intero minore o uguale a ${max}`;
            }
            return true;
        }
    },
    minFloat: (min) => {
        return value => {
            if (value) {
                const val = utils.toFloat(value, min - 1)
                if (val < min) return `deve essere un numero maggiore o uguale a ${min}`;
            }
            return true;
        }
    },
    rangeInt: (min, max) => {
        return value => {
            if (value) {
                const val = utils.toInt(value, min - 1)
                if (val < min || val > max) return `il valore deve essere tra ${min} e ${max}`;
            }
            return true;
        }
    },
    rangeFloat: (min, max) => {
        return value => {
            if (value) {
                const val = utils.toFloat(value, min - 1)
                if (val < min || val > max) return `il valore deve essere tra ${min} e ${max}`;
            }
            return true;
        }
    },
    requiredIf: (cond) => {
        return v => {
            if (!cond) return true
            return !!v || 'campo richiesto';
        }
    },
    notEmpty: (v) => {
        if (v && v.length) return true;
        return 'campo richiesto'
    },
    required: (v) => !!v || 'campo richiesto',
    slug: (v) => !!v && /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(v) || 'uno slug può contenere solo lettere minuscole e trattini',
    password: (v) => !v || utils.isValidPassword(v) || 'una password deve avere almeno 8 characters, 1 lettera, 1 numero ed un carattere speciale',
    sameAs: (password) => {
        return (v) => !v || password === v || 'password e conferma password devono essere uguali'
    }
}