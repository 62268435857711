import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api'
import log from '../log'
import router from '../router'
import {roles, rolesVal} from "@/utils/consts";
import config from "@/config";
Vue.use(Vuex)
let intv;
export default new Vuex.Store({
  state: {
    token: sessionStorage.token || null,
    profile: null,
    dialogMessage: '',
    successDialog: false,
    warningDialog: false,
    errorDialog: false,
    infoDialog: false,
    confirmDialog: false,
    confirmDialogTitle: 'Richiesta conferma',
    confirmDialogMessage: 'Sei sicuro?',
    confirmDialogCallback: null,
    languageMap : {},
    collections: [],
  },
  getters : {
    token: (state) => state.token,
    profile: (state) => state.profile,
    isAdmin: (state) => state.profile && rolesVal(state.profile.role) >= rolesVal(roles.admin),
    adminUrl: (state) => config.adminUrl,
    editorUrl: (state) => config.editorUrl,
    confirmDialogMessage: (state) => state.confirmDialogMessage,
    languageMap: (state) => state.languageMap,
    collections: (state) => state.collections,
  },
  mutations: {
    logout: (state) => {
      state.token = null;
      state.profile = null;
      sessionStorage.removeItem('token');
      if (router.currentRoute.name !== 'login') router.replace('/login')
    },
    token: (state, {token}) => {
      state.token = token;
      if (token)
        sessionStorage.setItem("token", token);
      else
        sessionStorage.removeItem("token");
    },
    profile: (state, {profile}) => {
      state.profile = profile;
    },
    message: (state, {type, message}) => {
      switch (type) {
        case 'success':
          state.successDialog = true;
          state.dialogMessage = message;
          break;
        case 'warning':
          state.warningDialog = true;
          state.dialogMessage = message;
          break;
        case 'error':
          state.errorDialog = true;
          state.dialogMessage = message;
          break;
        case 'info':
        default:
          state.infoDialog = true;
          state.dialogMessage = message;
          break;
      }
    },
    confirm: (state, {title, message, callback}) => {
      state.confirmDialogTitle = title || 'Richiesta conferma';
      state.confirmDialogMessage = message || 'Sei sicuro?';
      state.confirmDialogCallback = callback || null;
      state.confirmDialog = true;
    },
    setData: (state, {key, data, def }) => {
      def = def || null;
      state[key] = data ? data : def;
    },
  },
  actions: {
    login: ({commit}, {email, password}) => {
      return api.login(email, password);
    },
    async refreshToken({commit}) {
      if(!sessionStorage.token) return;
      log.d("refreshToken()")
      try {
        let reply = await api.heartbeat(sessionStorage.token)
        commit('token', {token: reply.data.authToken})
        commit('profile', {profile: reply.data.user})
      } catch (err) {
        commit('logout')
        return
      }
      clearInterval(intv);
      intv = setInterval(async () => {
        if(!sessionStorage.token) {
          clearInterval(intv)
          return;
        }
        try {
          let reply = await api.heartbeat(sessionStorage.token)
          commit('token', {token: reply.data.token})
          commit('profile', {profile: reply.data.user})
        } catch (err) {
          commit('logout')
          clearInterval(intv)
        }
      }, 1800000); // ogni 30 minuti
    },
    logout: ({commit}) => {
      commit('logout')
    },
    setToken: ({commit}, {token}) => {
      commit('token', {token})
    },
    setProfile: ({commit}, {profile}) => {
      commit('profile', {profile})
    },
    sendSuccess({commit}, {message}) {
      commit('message', {type: 'success', message})
    },
    sendWarning({commit}, {message}) {
      commit('message', {type: 'warning', message})
    },
    sendError({commit}, {message}) {
      commit('message', {type: 'error', message})
    },
    sendInfo({commit}, {message}) {
      commit('message', {type: 'info', message})
    },
    requestConfirm({commit}, {title, message, callback}) {
      commit('confirm', {title, message, callback})
    },
    load({commit}, {collection, filter}) {
      let {query, limit, page, sort } = filter;
      return api.apiList(sessionStorage.token, collection, query, limit, page, sort);
    },
    get({commit}, {collection, id}) {
      return api.apiGet(sessionStorage.token, collection, id);
    },
    insert({commit}, {collection, data}) {
      return api.apiPost(sessionStorage.token, collection, data);
    },
    update({commit}, {collection, data}) {
      return api.apiPut(sessionStorage.token, collection, data);
    },
    delete({commit}, {collection, id}) {
      return api.apiDelete(sessionStorage.token, collection, id);
    },
    upload({commit}, {where, formData}) {
      return api.apiUpload(sessionStorage.token, where, formData);
    },
    rijksLoad({commit}, { language, filter }) {
      let { query, limit, page, sort } = filter;
      return api.rijksLoad( sessionStorage.token,language, query, limit, page, sort);
    },
    rijksGet({commit}, { language, id }) {
      return api.rijksGet( sessionStorage.token,language, id);
    },
    rijksImport({commit}, { language, id, collectionId }) {
      return api.rijksImport( sessionStorage.token,language, id, collectionId);
    },
    async refreshCollection({commit}, {collection}) {
      try {
        let {data} = await api.apiList(sessionStorage.token, collection, {}, 0, 1, null);
        if (data) commit('setData', {key:collection, data, def : []});
      } catch (e) {
        log.d(e)
        return false
      }
      return true
    },
    async refreshMainCollection({commit}, {collection}) {
      try {
        let {data} = await api.mainApiList(sessionStorage.token, collection, {}, 0, 1, null);
        if (data) commit('setData', {key:collection, data, def : []});
      } catch (e) {
        log.d(e)
        return false
      }
      return true
    },
  },
  modules: {}
})
