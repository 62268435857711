import axios from 'axios'
import log from '../log';
import config from "../config";

const mainUrl = config.mainServer;
const baseUrl = config.server;
const rijksUrl = config.rijksmuseum_server;

const handleError = (error, reject) => {
    if (error.response) {
        // Request made and server responded
        log.d(error.response.data);
        log.d(error.response.status);
        log.d(error.response.headers);
        const message = error.response.data ? error.response.data.message : error.response.data
        reject({status: error.response.status, message})
    } else if (error.request) {
        // The request was made but no response was received
        log.d(error.request);
        reject({status: 500, message: 'errore di connessione al server'})
    } else {
        // Something happened in setting up the request that triggered an Error
        log.d(error.message)
        reject({status: 500, message: 'errore di connessione al server'});
    }

}
export default {
    login(username, password) {
        return new Promise((resolve, reject) => {
            axios.post(`${mainUrl}/api/login`, {username, password, client_uid : 'backoffice' }).then((response) => {
                log.d(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        })

    },
    heartbeat(token) {
        return new Promise((resolve, reject) => {
            axios.get(`${mainUrl}/api/heartbeat`, {headers: {'x-auth-token': token}}).then((response) => {
                log.d(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        })
    },
    apiList(token, collection, query, limit, page, sort) {
        return new Promise((resolve, reject) => {
            let url = `${baseUrl}/api/${collection}?limit=${limit}&page=${page - 1}`;
            for (const k in query) {
                if (query.hasOwnProperty(k) && query[k] !== undefined && query[k] !== null)
                    url += "&" + k + "=" + query[k];
            }
            if (sort) {
                url += "&sort=" + sort;
            }

            const config = token ? {headers: {'x-auth-token': token}} : {};
            axios.get(url, config).then((response) => {
                log.d(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        })
    },
    mainApiList(token, collection, query, limit, page, sort) {
        return new Promise((resolve, reject) => {
            let url = `${mainUrl}/api/${collection}?limit=${limit}&page=${page - 1}`;
            for (const k in query) {
                if (query.hasOwnProperty(k) && query[k] !== undefined && query[k] !== null)
                    url += "&" + k + "=" + query[k];
            }
            if (sort) {
                url += "&sort=" + sort;
            }

            const config = token ? {headers: {'x-auth-token': token}} : {};
            axios.get(url, config).then((response) => {
                log.d(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        })
    },
    apiGet(token, collection, id) {
        return new Promise((resolve, reject) => {
            let url = id ? `${baseUrl}/api/${collection}/${id}` : `${baseUrl}/api/${collection}`;
            const config = token ? {headers: {'x-auth-token': token}} : {};
            axios.get(url, config).then((response) => {
                log.d(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    },

    apiPost(token, collection, data) {
        return new Promise((resolve, reject) => {
            let url = `${baseUrl}/api/${collection}`;
            const config = token ? {headers: {'x-auth-token': token}} : {};
            axios.post(url, data, config).then((response) => {
                log.d(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    },


    apiUpload(token, path, data) {
        return new Promise((resolve, reject) => {
            let url = `${baseUrl}/api/${path}`;
            const config = token ? {
                headers: {'x-auth-token': token, 'Content-Type': 'multipart/form-data'},
            } : {
                headers: {'Content-Type': 'multipart/form-data'}
            };
            axios.post(url, data, config).then((response) => {
                log.d(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    },


    apiPut(token, collection, data) {
        return new Promise((resolve, reject) => {
            let url = `${baseUrl}/api/${collection}/${data._id}`;
            const config = token ? {headers: {'x-auth-token': token}} : {};
            axios.put(url, data, config).then((response) => {
                log.d(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    },
    apiDelete(token, collection, id) {
        return new Promise((resolve, reject) => {
            let url = `${baseUrl}/api/${collection}/${id}`;
            const config = token ? {headers: {'x-auth-token': token}} : {};
            axios.delete(url, config).then((response) => {
                log.d(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    },
    get(url) {
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                log.d(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    },

    rijksLoad( token, language, query, limit, page, sort ) {
        return new Promise((resolve, reject) => {
            let url = `${rijksUrl}${language}?ps=${limit}&p=${page}`;
            const config = token ? {headers: {'x-auth-token': token}} : {};
            for (const k in query) {
                if (query.hasOwnProperty(k) && query[k] !== undefined && query[k] !== null)
                    url += "&" + k + "=" + query[k];
            }
            if (sort) {
                url += "&s=" + sort;
            }
            axios.get(url, config).then((response) => {
                log.d(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    },
    rijksGet( token, language, id ) {
        return new Promise((resolve, reject) => {
            let url = `${rijksUrl}${language}/${id}`;
            const config = token ? {headers: {'x-auth-token': token}} : {};
            axios.get(url, config).then((response) => {
                log.d(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    },

    rijksImport( token, language, id, collectionId ) {
        return new Promise((resolve, reject) => {
            let url = `${rijksUrl}${language}/${id}/${collectionId}`;
            const config = token ? {headers: {'x-auth-token': token}} : {};
            axios.get(url, config).then((response) => {
                log.d(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    }
}