<template>
  <v-app>
    <v-app-bar app color="primary" dark clipped-left elevation="2" v-if="loggedIn">
      <v-app-bar-nav-icon @click="mini = !mini" />
      <v-img alt="BikeBoom Logo" contain src="./assets/logo_xxh_noborder.png" max-width="190" max-height="36" width="190" height="36" />
      <div class="d-flex fill-height align-end pl-3 pb-1">
        <h4>OA <span class="secondary--text">ADMIN<span class="red--text" v-if="!production">&nbsp;DEV</span></span></h4>
      </div>
      <v-spacer />
      <v-toolbar-items>
        <v-btn color="accent" text class="white">OA</v-btn>
        <v-btn v-if="isAdmin" color="white" :href="`${adminUrl}?xt=${token}`" text>Admin</v-btn>
        <v-btn color="white" :href="`${editorUrl}?xt=${token}`" text>Editor</v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer clipped app hide-overlay :mini-variant="mini" permanent dark v-if="loggedIn">
      <v-list dense nav>
        <v-list-item v-for="item in pages" :key="item.title" link :to="item.to">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-slide-x-transition>
        <router-view :key="$route.path"/>
      </v-slide-x-transition>

      <v-dialog v-model="confirmDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">{{ confirmDialogTitle }}</v-card-title>
          <v-card-text>
            <p class="body-1" v-html="confirmDialogMessage"></p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click.native="confirmDialogCallback(false); confirmDialog = false">No</v-btn>
            <v-btn color="error" text @click.native="confirmDialogCallback(true);  confirmDialog = false">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
          dark
          :timeout="5000"
          :color="snackbarColor"
          top
          multi-line
          v-model="snackbar">
        {{ dialogMessage }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";
import config from "@/config";

export default {
  name: 'App',

  data: () => ({
    mini: false,
  }),
  computed : {
    ...mapState(['confirmDialogTitle', 'confirmDialogCallback', 'dialogMessage']),
    ...mapGetters(['token', 'profile', 'confirmDialogMessage','isAdmin','adminUrl','editorUrl']),
    production() {
      return config.production;
    },
    pages() {
      return [
        { title : "Dashboard" , icon : 'mdi-monitor-dashboard', to : '/'},
        /*{ title : "Collezioni OAI" , icon : 'mdi-source-repository-multiple', to : '/oai'},*/
        { title : "Chicago" , icon : this.mini ? 'mdi-alpha-c' : 'mdi-database-marker', to : '/chicago'},
        { title : "Cleveland Art" , icon : this.mini ? 'mdi-alpha-c' : 'mdi-database-marker', to : '/cleveland'},
        { title : "Museo Egizio" , icon : this.mini ? 'mdi-alpha-e' : 'mdi-database-marker', to : '/egizio'},
        { title : "Finnish National Gallery" , icon : this.mini ? 'mdi-alpha-f' : 'mdi-database-marker', to : '/finnish'},
        { title : "Met" , icon : this.mini ? 'mdi-alpha-m' : 'mdi-database-marker', to : '/met'},
        { title : "Reims dipinti" , icon : this.mini ? 'mdi-alpha-r' : 'mdi-database-marker', to : '/reims'},
        { title : "Rijksmuseum" , icon : this.mini ? 'mdi-alpha-r' : 'mdi-database-marker', to : '/rijksmuseum'},
        { title : "SMK" , icon : this.mini ? 'mdi-alpha-s' : 'mdi-database-marker', to : '/smk'},
        { title : "Städel Museum" , icon : this.mini ? 'mdi-alpha-s' : 'mdi-database-marker', to : '/generic'},


        { title : "Impostazioni" , icon : 'mdi-cog', to : '/settings'},
      ]
    },
    loggedIn() {
      return this.profile != null
    },
    successDialog: {
      get() {
        return this.$store.state.successDialog;
      },
      set(newVal) {
        this.$store.state.successDialog = newVal;
      }
    },
    warningDialog: {
      get() {
        return this.$store.state.warningDialog;
      },
      set(newVal) {
        this.$store.state.warningDialog = newVal;
      }
    },
    errorDialog: {
      get() {
        return this.$store.state.errorDialog;
      },
      set(newVal) {
        this.$store.state.errorDialog = newVal;
      }
    },
    infoDialog: {
      get() {
        return this.$store.state.infoDialog;
      },
      set(newVal) {
        this.$store.state.infoDialog = newVal;
      }
    },
    confirmDialog: {
      get() {
        return this.$store.state.confirmDialog;
      },
      set(newVal) {
        this.$store.state.confirmDialog = newVal;
      }
    },
    snackbar: {
      get() {
        return this.successDialog || this.warningDialog || this.errorDialog || this.infoDialog;
      },
      set(newVal) {
        this.successDialog = newVal;
        this.warningDialog = newVal;
        this.errorDialog = newVal;
        this.infoDialog = newVal;
      }
    },
    snackbarColor() {
      if (this.successDialog) return 'success';
      if (this.errorDialog) return 'error';
      if (this.warningDialog) return 'warning';
      return undefined;
    },
  },
  methods: {
    ...mapActions(['logout', 'refreshToken']),
  },
  async mounted() {
    if(this.$route.query.xt) {
      await this.$store.commit("token", {token:this.$route.query.xt})
    }
    if (this.token) {
      await this.refreshToken()
    } else {
      this.logout()
    }
  }
};
</script>
