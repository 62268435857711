import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/pages/Dashboard.vue'
import Login from "@/pages/Login";
import Rijksmuseum from "@/pages/Rijksmuseum";
import Met from "@/pages/Met";
import Settings from "@/pages/Settings";
import OaiCollections from "@/pages/OaiCollections";
import OaiCollectionForm from "@/pages/OaiCollectionForm";
import Smk from "@/pages/Smk";
import Cleveland from "@/pages/Cleveland";
import Staedel from "@/pages/Staedel";
import Egizio from "@/pages/Egizio";
import ReimsPaintings from "@/pages/ReimsPaintings";
import Finnish from "@/pages/Finnish";
import Chicago from "@/pages/Chicago";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/met',
    name: 'met',
    component: Met,
  },
  {
    path: '/cleveland',
    name: 'cleveland',
    component: Cleveland,
  },
  {
    path: '/smk',
    name: 'smk',
    component: Smk,
  },
  {
    path: '/generic',
    name: 'generic',
    component: Staedel,
  },
  {
    path: '/egizio',
    name: 'egizio',
    component: Egizio,
  },
  {
    path: '/reims',
    name: 'reims',
    component: ReimsPaintings,
  },
  {
    path: '/finnish',
    name: 'finnish',
    component: Finnish,
  },
  {
    path: '/rijksmuseum',
    name: 'rijksmuseum',
    component: Rijksmuseum,
  },
  {
    path: '/chicago',
    name: 'chicago',
    component: Chicago,
  },

  {
    path: '/settings',
    name: 'settings',
    component: Settings,
  },
  {
    path: '/oai',
    name: 'oai',
    component: OaiCollections,
  },
  {
    path: '/oai/:id',
    name: 'oai form',
    component: OaiCollectionForm,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
