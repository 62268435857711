<template>
  <v-container class="pa-6">
    <v-row>
      <v-col cols="12" class="d-flex">
        <h2 class="title">{{ title }}</h2>
        <v-spacer />
        <v-btn color="accent" text small @click="save(1)" :loading="loading">Salva</v-btn>
        <v-btn color="accent" text small @click="save(2)" :loading="loading">Salva e continua
        </v-btn>
        <v-btn color="accent" text small @click="save(3)" :loading="loading">Salva e aggiungi
        </v-btn>
      </v-col>
      <v-col cols="12" class="pt-0"><v-divider /></v-col>
      <v-col cols="12">
        <v-form ref="form" @submit.prevent="save(1)">
          <v-row>
            <v-col cols="12" md="6" lg="4">
              <v-text-field outlined dense label="Nome*" v-model="editingItem.name"
                            :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field outlined dense label="Nome tabella db*"  :rules="[rules.required]" v-model="editingItem.db_collection"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-autocomplete outlined dense label="Collezione ArtCentrica*"  :rules="[rules.required]" :items="collectionOptions" v-model="editingItem.collection_id"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field outlined dense label="Url api OAI*" :rules="[rules.required]" v-model="editingItem.oai_endpoint"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-select outlined dense label="Formato OAI*" :rules="[rules.required]" :items="oaiFormatOptions" v-model="editingItem.oai_format"></v-select>
            </v-col>
            <v-col cols="12">
              <h4>Header extra nelle chiamate</h4>
              <ul>
                <li v-for="(k,v) in editingItem.extra_headers" :key="k">
                  <strong>{{k}}</strong>: {{v}}
                  <v-btn icon color="error" small @click="removeHeader(k)"><v-icon>mdi-close</v-icon></v-btn>
                </li>
              </ul>
              <v-row>
                <v-col cols="12" md="4" >
                  <v-text-field outlined dense label="Nome header" v-model="headerName"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" >
                  <v-text-field outlined dense label="Valore header" v-model="headerValue"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" >
                  <v-btn small color="accent" :disabled="!headerName || !headerValue" @click="addHeader()">Aggiungi header</v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6" lg="4">
              <v-text-field outlined dense label="Basic auth credentials" v-model="editingItem.basic_auth"></v-text-field>
            </v-col>

            <v-col cols="12" md="6" lg="4">
              <v-switch color="accent" label="Download in un unica chiamata" persistent-hint hint="utile per alcune api che non funzionano come dovrebbero"></v-switch>
            </v-col>

            <v-col cols="12" v-if="editingItem._id">
              <h4>Dettagli</h4>
              <p><strong>Download eseguito</strong>: {{editingItem.download_done}}</p>
              <p><strong>L'ultimo download ha scaricato dati nuovi</strong>: {{editingItem.last_download_has_new_data}}</p>
              <p><strong>Ultimo errore</strong>: {{editingItem.last_error}}</p>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="12" class="pb-0"><v-divider /></v-col>
      <v-col cols="12" class="d-flex"><v-spacer />
        <v-btn color="accent" text small @click="save(1)" :loading="loading">Salva</v-btn>
        <v-btn color="accent" text small @click="save(2)" :loading="loading">Salva e continua
        </v-btn>
        <v-btn color="accent" text small @click="save(3)" :loading="loading">Salva e aggiungi
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import log from '../log';
import {mapActions, mapGetters} from 'vuex';
import rules from "../utils/rules";

export default {
  data() {
    return {
      loading: false,
      headerName : '',
      headerValue : '',
      editingItem: {
        name: '',
        collection_id: '',
        db_collection: '',
        oai_endpoint: '',
        oai_format: '',
        extra_headers: {},
        basic_auth: '',
        download_in_one_go: false,
        download_done: false,
        last_download_has_new_data: false,
        last_error: '',
      },
      oaiFormatOptions : [
        { text : 'Oai dc', value : 'oai_dc'}
      ],
      rules: {
        ...rules,
      }
    }
  },
  computed: {
    ...mapGetters(['collections']),
    collectionOptions() {
      return this.collections.map(item => {
        let text = item.title.it|| item.title.en
        return { text, value : item._id }
      })
    },
    updating() {
      return !!this.editingItem._id
    },
    title() {
      return this.$route.params.id === 'new' ? 'New bioregion' : 'Edit bioregion';
    },
  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'get', 'update', 'insert','refreshMainCollection']),
    addHeader() {
      this.editingItem.extra_headers[this.headerName] = this.headerValue
    },
    removeHeader(key) {
      this.editingItem.extra_headers[key] = undefined;
      delete this.editingItem.extra_headers[key];
    },
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({collection: 'oai', id}).then((reply) => {
        this.loading = false;
        if (reply.data) {
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : "there was an error while loading data";
        this.sendError({message});
        this.go(-1);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
    async save(afterAction) {
      let updating = this.updating;
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      let saveFn = updating ? this.update : this.insert;

      try {
        let {data} = await saveFn({collection: 'oai', data: this.editingItem});
        this.sendSuccess({message: "Data saved!"});
        this.loading = false;
        switch (afterAction) {
          case 1:
            await this.$router.replace('/oai')
            break
          case 2:
            this.editingItem = data;
            if (this.$route.params.id === 'new') {
              await this.$router.replace(`/oai/${data._id}`);
            }
            break
          case 3:
            this.editingItem = {
              name: '',
              collection_id: '',
              db_collection: '',
              oai_endpoint: '',
              oai_format: '',
              extra_headers: {},
              basic_auth: '',
              download_in_one_go: false,
              download_done: false,
              last_download_has_new_data: false,
              last_error: '',
            }
            if (this.$route.params.id !== 'new') {
              await this.$router.replace(`/oai/new`);
            }
            break
        }

      } catch (err) {
        this.loading = false;
        log.d("Save oai error:", err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if (err.statusCode === 401) {
          await this.$router.replace("/");
          this.logout();
        }
      }
    }
  },
  mounted() {
    this.refreshMainCollection({collection:'collections'});
    if (this.$route.params.id !== 'new') {
      this.loadData()
    }
  }
}
</script>
