import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import it from 'vuetify/lib/locale/it';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#ce6c3d',
        secondary: '#1C222B',
        accent: '#ce6c3d',
        error: '#d50000',
        info: '#00b8d4',
        success: '#00c853',
        warning: '#ff6d00'
      },
      dark: {
        primary: '#ce6c3d',
        secondary: '#1C222B',
        accent: '#ce6c3d',
        error: '#d50000',
        info: '#00b8d4',
        success: '#00c853',
        warning: '#ff6d00'
      },
    },
  },
    lang: {
      locales: { it },
      current: 'it',
    },
});
