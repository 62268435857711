<template>
  <v-container fluid class="full-width fill-height secondary">
    <div class="d-flex justify-center align-center fill-height full-width">
      <v-row no-gutters class="d-flex justify-center" style="max-width: 360px">
        <v-col cols="12" class="white--text d-flex justify-center align-center pb-4" style="position: relative"  >
          <v-img class="mb-2" alt="ArtCentrica Logo" contain src="../assets/logo_xxh_noborder.png" max-width="190" max-height="36" width="190" height="36"/>
          <div class="d-flex fill-height align-end pl-3">
            <h3>OA <span class="accent--text">ADMIN</span></h3>
          </div>

        </v-col>
        <v-col cols="12">
          <v-card :loading="loading" elevation="1">
            <v-card-text class="pt-6 pb-6">
              <v-form @submit.prevent="onSubmit()">
                <v-text-field
                    outlined dense
                    color="secondary"
                    v-model="email"
                    label="Email"
                    name="email"
                    type="email"
                    :rules="[rules.required, rules.email]"
                ></v-text-field>
                <v-text-field
                    outlined dense
                    color="secondary"
                    v-model="password"
                    name="password"
                    label="Password"
                    :rules="[rules.required, rules.loginError(loginError), rules.permError(permissionsError), rules.serverError(serverError)]"
                    :type="showPwd ? 'text' : 'password'" :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPwd = !showPwd"></v-text-field>
                <v-btn class="flex-grow-1 flex-shrink-0 full-width" color="accent" type="submit"  :loading="loading">Login</v-btn>
              </v-form>
            </v-card-text>
          </v-card>

        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import rules from "../utils/rules";
import { roles, rolesVal } from '@/utils/consts';

export default {
  name: "Login",
  data() {
    return {
      loading : false,
      showPwd : false,
      email : '',
      password: '',
      loginError: false,
      permissionsError: false,
      serverError: null,
      rules : {
        ...rules,
        loginError: ( value ) => {
          return value ? "la password è sbagliata o l'account non esiste" : true
        },
        permError: ( value ) => {
          return value ? "non hai i permessi per accedere a questo pannello" : true
        },
        serverError: ( msg ) => {
          return msg ? msg : true
        }
      }
    }
  },
  computed : {
    ...mapGetters(['profile']),
    isLoggedIn() {
      return !!this.profile;
    },
  },
  methods : {
    ...mapActions(['login','logout','setToken','setProfile','sendError']),
    onError(e) {
      if(e) {
        if(e.status === 401) this.loginError = true;
        else this.serverError = e.message;
        this.loading = false;
        this.logout()
      }
    },
    async onSubmit() {
      this.permissionsError = false;
      this.loginError = false;
      this.loading = true;
      this.serverError = null;
      try {
        let reply = await this.login({email: this.email, password: this.password})
        this.loading = false;
        if(rolesVal(reply.data.user.role) < rolesVal(roles.editor)) {
          this.permissionsError = true;
          return
        }
        this.setToken({token : reply.data.authToken })
        this.setProfile({profile : reply.data.user })
        await this.$router.replace('/');
      } catch (err) {
        this.onError(err)
      }
    }
  },
  mounted() {
    if(this.isLoggedIn) {
      this.$router.replace('/');
    }
  }
}
</script>

<style scoped>

</style>