<template>
  <v-container class="pa-6">
    <v-row row>
      <v-col cols="12"><h2 class="display-1">{{L('Database Rijksmuseum')}}</h2></v-col>
      <v-col cols="12"><v-divider/></v-col>
      <v-col cols="12">
        <h3>{{L('Filters')}}:</h3>
        <v-chip class="mr-2" v-for="(filter, i) in filters" :key="`filter_${i}`" close @click="editFilter(filter)" @click:close="removeFilter(i)">{{filter.text}}: {{filter.filter}}</v-chip>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3" >
        <v-select color="accent" :label="L('Sort by')" :items="sortOptions" v-model="sortBy" clearable @change="applyFilter()" filled rounded></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-text-field type="text" filled rounded dense hide-details v-model="qSearch" :label="L('Search')"></v-text-field>
      </v-col>


      <v-col cols="12" sm="6" md="4" lg="3" v-if="!!qSearch">
        <v-btn color="accent" @click="applyFilter()">{{L('Search')}}</v-btn>
      </v-col>

      <v-col cols="12" class="d-flex align-center">
        <v-btn color="accent" class="flex-grow-0 flex-shrink-1" @click="selectAll()">{{L('Select all')}}</v-btn>
        <v-autocomplete filled rounded dense class="flex-grow-0 flex-shrink-1 ml-2" :label="L('Collection of import')" hide-details v-model="collectionId" :items="collectionOptions" clearable></v-autocomplete>
        <v-btn color="accent" class="flex-grow-0 flex-shrink-1 ml-2" @click="generateSelected('en')" :disabled="!collectionId">{{L('Import selected')}}</v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                      :no-data-text="L('There are no records yet')" :footer-props="footerProps" >

          <template v-slot:item.select="{ item }">
            <v-checkbox v-model="selectedItems[item.objectNumber]" :key="item.objectNumber + 'check' + refreshKey"></v-checkbox>
          </template>
          <template v-slot:item.headerImage.url="{ item }">
            <v-img :src="item.headerImage.url" width="100" height="50" contain class="black" />
          </template>
          <template v-slot:item.view="{ item }">
            <v-btn icon @click="openView(item)" color="accent"><v-icon>mdi-file-find</v-icon></v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>


    <v-dialog  v-model="viewDialog" max-width="800">
      <v-card v-if="viewItem">
        <v-card-title>{{viewItem.title}}</v-card-title>
        <v-card-text>
          <ul>
            <li><strong>id</strong>: {{viewItem.id}}</li>
            <li><strong>objectNumber</strong>: {{viewItem.objectNumber}}</li>
            <li><strong>priref</strong>: {{viewItem.priref}}</li>
            <li><strong>copyrightHolder</strong>: {{viewItem.copyrightHolder}}</li>
            <li v-if="viewItem.webImage"><strong>webImage</strong>: <a :href="viewItem.webImage.url">{{viewItem.webImage.url}}</a> ({{viewItem.webImage.width}}x{{viewItem.webImage.height}})</li>
            <li><strong>colors</strong>: {{viewItem.colors.map(item => item.hex + "("+ item.percentage +"%)").join(', ')}}</li>
            <li><strong>normalizedColors</strong>: {{viewItem.normalizedColors.map(item => item.hex + "("+ item.percentage +"%)").join(', ')}}</li>
            <li><strong>normalized32Colors</strong>: {{viewItem.normalized32Colors.map(item => item.hex + "("+ item.percentage +"%)").join(', ')}}</li>
            <li><strong>titles</strong>: {{viewItem.titles.join('|')}}</li>
            <li><strong>description</strong>: {{viewItem.description}}</li>
            <li><strong>labelText</strong>: {{viewItem.labelText}}</li>
            <li><strong>objectTypes</strong>: {{viewItem.objectTypes.join(', ')}}</li>
            <li><strong>objectCollection</strong>: {{viewItem.objectCollection.join(', ')}}</li>
            <li><strong>principalMakers</strong>: {{viewItem.principalMakers.map(item => item.name).join(', ')}}</li>
            <li><strong>plaqueDescriptionEnglish</strong>: {{viewItem.plaqueDescriptionEnglish}}</li>
            <li><strong>principalMaker</strong>: {{viewItem.principalMaker}}</li>
            <li><strong>artistRole</strong>: {{viewItem.artistRole}}</li>
            <li><strong>materials</strong>: {{viewItem.materials.join(', ')}}</li>
            <li><strong>techniques</strong>: {{viewItem.techniques.join(', ')}}</li>
            <li><strong>productionPlaces</strong>: {{viewItem.productionPlaces.join(', ')}}</li>
            <li><strong>dating</strong>: presentingDate {{viewItem.dating.presentingDate}}, sortingDate {{viewItem.dating.sortingDate}}, century {{viewItem.dating.period}}, yearEarly {{viewItem.dating.yearEarly}}, yearLate {{viewItem.dating.yearLate}}</li>
            <li><strong>classification</strong>: {{viewItem.classification.iconClassIdentifier}}</li>
            <li><strong>hasImage</strong>: {{viewItem.hasImage}}</li>
            <li><strong>historicalPersons</strong>: {{viewItem.historicalPersons}}</li>
            <li><strong>documentation</strong>: {{viewItem.documentation}}</li>
            <li><strong>principalOrFirstMaker</strong>: {{viewItem.principalOrFirstMaker}}</li>
            <li><strong>dimensions</strong>: {{viewItem.dimensions}}</li>
            <li><strong>physicalMedium</strong>: {{viewItem.physicalMedium}}</li>
            <li><strong>longTitle</strong>: {{viewItem.longTitle}}</li>
            <li><strong>subTitle</strong>: {{viewItem.subTitle}}</li>
            <li><strong>scLabelLine</strong>: {{viewItem.scLabelLine}}</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="accent" @click="viewItem = null; viewDialog = false;">{{L('Close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import utils from "../utils";
import _ from "lodash";
export default {
  data () {
    return {
      collectionId : null,
      refreshKey : 0,
      viewDialog : false,
      viewItem : null,
      setImagesDialog : false,
      numImages : 3,
      images : {},
      columnsDialog: false,
      refreshTimeout : -1,
      filters : [],
      selectedItems : {},
      selectedProp : null,
      sortBy : null,
      selectedColumns : {
        object_number : true,
        is_highlight : true,
        is_public_domain : true,
        object_name : true,
        title : true,
        artist_display_name : true,
        artist_alpha_sort : true,
        rights_and_reproduction : true,
      },
      filterOptions : [
        { text : 'Ricerca libera', value : 'q', type : 'string'},
        { text : 'Autore', value : 'involvedMaker', type : 'string'},
        { text : 'Materiale', value : 'material', type : 'string'},
        { text : 'Tecnica', value : 'technique', type : 'string'},
        { text : 'Colore (#xxxxxx)', value : 'f.normalized32Colors.hex', type : 'string'},
        { text : 'Secolo', value : 'f.dating.period', type : 'int'},
        { text : 'Con immagine', value : 'imgonly', type : 'bool'},
        { text : 'Masterpieces', value : 'toppieces', type : 'bool'},
      ],
      hideIgnored : true,
      qSearch : null,
      collectionSearch : null,
      tableLoading : false,
      footerProps : {
        "items-per-page-text" : this.L('No. rows per page'),
        "items-per-page-options" : [20,50,100],
        "items-per-page" : 20
      },
      count : 0,
      items : [],
      pagination: {},
      query : {},
      boolOptions : [{text: 'Sì', value:'True'},{text: 'No', value:'False'}],
      sortOptions : [{text: 'Importanza', value:'relevance'},{text: 'Tipologia', value:'objecttype'},
        {text: 'Data (asc)', value:'chronologic'},{text: 'Data (desc)', value:'achronologic'},
        {text: 'Artista (asc)', value:'artist'},  {text: 'Artista (desc)', value:'artistdesc'}]
    }
  },
  watch: {
    pagination: {
      handler () {
        this.selectedItems = {}
        sessionStorage.rijksPagination = JSON.stringify(this.pagination);
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    },
  },
  computed : {
    ...mapGetters(['profile','collections','languageMap']),
    collectionOptions() {
      return this.collections.map(item => {
        let text = item.title.it || item.title.en
        return { text, value : item._id }
      })
    },
    headers(){
      return [
        { text : this.L('Selection'), sortable: false, align: 'center', value : 'select', width : 50 },
        { text : this.L('Image'), sortable: false, align: 'center', value : 'headerImage.url', width : 100 },
        { text : this.L('ID'), sortable: false, align: 'center', value : 'id' },
        { text : this.L('Object Number'), sortable: false, align: 'center', value : 'objectNumber' },
        { text : this.L('Title'), sortable: false, align: 'center', value : 'title' },
        { text : this.L('Author'), sortable: false, align: 'center', value : 'principalOrFirstMaker' },
        { text : this.L('Has Image'), sortable: false, align: 'center', value : 'hasImage' },
        { text : this.L('Permit Download'), sortable: false, align: 'center', value : 'permitDownload' },
        { text : this.L('View'), sortable: false, align: 'center', value : 'view', width : 50 },
      ]
    },
    loading : {
      get() {
        return this.$store.state.loading
      },
      set(val) {
        this.$store.state.loading = val
      }
    },
    loadingMessage : {
      get() {
        return this.$store.state.loadingMessage
      },
      set(val) {
        this.$store.state.loadingMessage = val
      }
    }
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'insert', 'get', 'delete','refreshMainCollection','rijksLoad','rijksGet','rijksImport']),
    L( key ) {
      return this.languageMap && this.languageMap[key] ? this.languageMap[key] : key;
    },
    async openView(item) {
      try {
        let reply = await this.rijksGet({language:'en', id : item.objectNumber })
        this.viewItem = reply.artObject;
        this.viewDialog = true
      } catch (e) {
        this.sendError({message : "Errore alla richiesta dei dettagli dell'opera (vedi console)"})
        console.log("rijks api error:", e)
      }
    },
    selectAll() {
      let pairs = _.toPairs(this.selectedItems);
      if(pairs.length < this.items.length) {
        for(let i = 0; i < this.items.length; i++) {
          this.selectedItems[this.items[i].objectNumber] = true;
        }
      } else {
        this.selectedItems = {}
      }
      this.refreshKey++;
    },
    async generateSelected(language) {
      if(!this.collectionId) return;
      let ids = _.keys(this.selectedItems);
      if(!ids.length) return

      this.loadingMessage = `Importati 0 di ${ids.length}`
      this.loading = true
      for(let i = 0; i < ids.length; i++) {
        let id = ids[i]
        this.loadingMessage = `Importati ${i} di ${ids.length}`
        try {
          await this.rijksImport({ collectionId : this.collectionId, id, language })
          this.selectedItems[id] = false;
        } catch (e) {
          this.sendError({message:"C'è stato un errore di importazione per l'elemento con id: " + id})
          console.log(`Import error of ${id}:`,e)
        }
      }

      this.loadingMessage = `Loading...`
      this.loading = false
      setTimeout(() => {
        this.sendSuccess({message: 'Importazione terminata (controlla la console per eventuali errori)'})
      }, 500)
    },
    editFilter(item) {
      let { text, value, type, filter} = item
      this.selectedProp = { text, value, type };
      this.qSearch = filter;
    },

    applyFilter() {
      this.query = {}
      if(this.qSearch) {
        this.query.q = this.qSearch;
      }
      this.pagination.page = 1;
      sessionStorage.rijksQuery = JSON.stringify(this.query);
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    refresh() {
      this.tableLoading = true;
      const { page, itemsPerPage } = this.pagination;

      let filter =  { query : this.query, limit : itemsPerPage, page, sort : this.sortBy}

      this.rijksLoad({ language : 'en', filter }).then((reply)=>{
        this.tableLoading = false;
        this.count = reply.count;
        this.items = reply.artObjects ? reply.artObjects : [];
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"Errore al caricamento del db rijks."});
        if(config.debug) console.error("Errore al caricamento del db rijks: ", err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    if(sessionStorage.rijksQuery) {
      this.query = JSON.parse(sessionStorage.rijksQuery);

      for(let k in this.query) {
        let type = 'string'
        if(k === 'f.dating.period') {
          type = 'int'
        } else if (k === 'imgonly' || k === 'toppieces') {
          type = 'bool'
        }
        let f = { filter : this.query[k] }
        let opt = utils.getObjectInArray(this.filterOptions, 'value', k)
        if(opt) {
          this.filters.push(Object.assign(f, opt))
        } else {
          this.query[k] = undefined
          delete this.query[k]
        }
      }
    }
    if(sessionStorage.rijksPagination) {
      this.pagination = JSON.parse(sessionStorage.rijksPagination);
    }
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.refresh, 250);
    this.refreshMainCollection({collection:'collections'});


  }
}
</script>
