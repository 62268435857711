<template>
  <v-container>
    TODO
  </v-container>
</template>

<script>

  export default {
    name: 'Dashboard',

    components: {

    },
  }
</script>
