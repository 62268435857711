const roles = {
    guest : "guest",
    user : "user",
    translator : "translator",
    editor : "editor",
    admin : "admin",
    root : "root",
};
const rolesVal = (role) => {
    switch(role) {
        case roles.root:
            return 100
        case roles.admin:
            return 90
        case roles.editor:
            return 60
        case roles.translator:
            return 40
        case roles.user:
            return 10
    }
    return 0
};

export { roles, rolesVal };