import _ from "lodash";
import moment from 'moment'

moment.locale('it')

const utils = {
    isValidPassword(password) {
        return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(password)
    },
    isValidEmail(mail) {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(mail)
    },
    isValidColor(hex) {
        return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(hex)
    },
    isValidUrl(url) {
        return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i.test(url)
    },
    isValidPhone(value) {
        return /^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$/i.test(value)
    },
    toFloat(val, defaultVal) {
        if (_.isNil(val)) return defaultVal;
        if (_.isNumber(val)) return val;
        if (_.isString(val)) {
            let num = parseFloat(val.replace(",", "."));
            if (!_.isNaN(num)) {
                return num;
            }
        }
        return defaultVal;
    },
    toInt(val, defaultVal) {
        if (_.isNil(val)) return defaultVal;
        if (_.isNumber(val)) return val;
        if (_.isString(val)) {
            let num = parseInt(val.replace(",", "."), 10);
            if (!_.isNaN(num)) {
                return num;
            }
        }
        return defaultVal;
    },
    stringSearch(value, test) {
        return value.toLowerCase().indexOf(test.toLowerCase()) !== -1;
    },
    sortTextOn(prop) {
        return (a, b) => {
            let textA = a[prop].toLowerCase();
            let textB = b[prop].toLowerCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }
    },
    sortFunction(property, direction) {
        if (!direction) direction = "asc";
        const one = direction === "asc" ? 1 : -1;
        return (a, b) => {
            if (a[property] > b[property]) {
                return one;
            }
            if (a[property] < b[property]) {
                return -one;
            }
            return 0;
        }
    },
    capText(text, maxChars) {
        if (text.length > maxChars) {
            return text.substr(0, maxChars - 3) + "..."
        }
        return text
    },
    copy: (obj) => {
        return JSON.parse(JSON.stringify(obj));
    },
    formatDate(date) {
        if (!date) return '-';
        return moment(date).format('L')
    },
    formatDateTime(date) {
        if (!date) return '-';
        return moment(date).format('L LT')
    },
    getFileExt(url) {
        return url.split('.').pop();
    },
    extractPagination(pagination, query) {
        const {sortBy, sortDesc, page, itemsPerPage} = pagination;
        let sort = null;
        let order = null;
        if (sortBy.length) {
            sort = sortBy[0];
            order = 'ASC';
            if (sortDesc[0]) order = 'DESC';
        }
        return {query, limit: itemsPerPage, page, sort, order};
    },
    getObjectInArray(items, byKey, value) {
        if (!items) return null;
        let filter = items.filter(item => item[byKey] === value)
        return filter.length ? filter[0] : null;
    }
};
export default utils;