<template>
  <v-container class="pa-6">
    <v-row>
      <v-col cols="12" class="d-flex">
        <h2 class="title">Impostazioni</h2>
        <v-spacer />
        <v-btn text color="accent" small :loading="loading" @click="save()">Salva</v-btn>
      </v-col>
      <v-col cols="12" class="pt-0"><v-divider /></v-col>
      <v-col cols="12" md="6" lg="3" xl="2">
        <v-text-field outlined dense label="N° max di job paralleli" v-model="editingItem.max_parallel_jobs" type="number"
                      :error="maxJobError" :error-messages="requiredErrorMessages(maxJobError)"></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="2">
        <v-text-field outlined dense label="Millisecondi tra un batch di job e l'altro" v-model="editingItem.milliseconds_between_jobs" type="number"
                      :error="jobWaitError" :error-messages="requiredErrorMessages(jobWaitError)"></v-text-field>
      </v-col>
      <v-col cols="12" class="pb-0"><v-divider /></v-col>
      <v-col cols="12" class="d-flex"><v-spacer /><v-btn text color="accent" small @click="save()" :loading="loading">Salva</v-btn></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import utils from "@/utils";
export default {
  name :"Settings",
  data () {
    return {
      loading : false,
      editingItem : {
        max_parallel_jobs : 2,
        milliseconds_between_jobs: 2000
      },
      maxJobError : false,
      jobWaitError : false,
    }
  },
  computed : {
    ...mapGetters(['token', 'profile']),
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'logout','get', 'insert', 'refreshCollection']),

    requiredErrorMessages(err) {
      if(err) return ['campo richiesto'];
      return []
    },
    loadData() {
      this.loading = true;
      this.get({ collection : 'settings' }).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message :'there was an error while loading data';
        this.sendError({message});
        this.go(-1);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    clearErrors() {
      this.maxJobError = false;
      this.jobWaitError = false;
    },
    async save() {
      this.clearErrors();
      this.editingItem.max_parallel_jobs = utils.toInt(this.editingItem.max_parallel_jobs, 0)
      if(this.editingItem.max_parallel_jobs <= 0) {
        this.maxJobError = true;
        return;
      }
      this.editingItem.milliseconds_between_jobs = utils.toInt(this.editingItem.milliseconds_between_jobs, 0)
      if(this.editingItem.milliseconds_between_jobs <= 0) {
        this.jobWaitError = true;
        return;
      }
      try {
        let { data } = await this.insert({collection:'settings', data : this.editingItem});
        this.editingItem = data;
        this.sendSuccess({ message : "Impostazioni salvate!"});
      } catch (err) {
        let message = err.message ? err.message : 'there was an error while saving data';
        this.sendError({ message });
        if(err.statusCode === 401) {
          await this.$router.replace("/");
          this.logout();
        }
      }
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>
