var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-6"},[_c('v-row',{attrs:{"row":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('h2',{staticClass:"display-1"},[_vm._v("Database OAI")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"accent"},on:{"click":function($event){return _vm.$router.push('/oai/new')}}},[_vm._v("Nuovo")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","return-object":"","label":"Cerca per nome"},on:{"input":function($event){return _vm.applyFilter()}},model:{value:(_vm.query.name),callback:function ($$v) {_vm.$set(_vm.query, "name", $$v)},expression:"query.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.count,"loading":_vm.tableLoading,"no-data-text":_vm.L('There are no records yet'),"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.colletion_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getCollectionName(item.collection_id)))])]}},{key:"item.download_done",fn:function(ref){
var item = ref.item;
return [(item.download_done)?_c('span',{staticClass:"font-weight-bold success--text"},[_vm._v("Sì")]):_c('span',{staticClass:"font-weight-bold error--text"},[_vm._v("No")])]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"accent"},on:{"click":function($event){return _vm.downloadOaiCollection(item._id)}}},[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)]}},{key:"item.sync",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!item.download_done,"icon":"","color":"accent"},on:{"click":function($event){return _vm.syncOaiCollection(item._id)}}},[_c('v-icon',[_vm._v("mdi-database-sync")])],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"accent"},on:{"click":function($event){return _vm.$router.push(("/oai/" + (item._id)))}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.requestDelete(item)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }